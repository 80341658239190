html div#iubenda-cs-banner.iubenda-cs-default-floating div.iubenda-cs-content {
  width: 100% !important;
  padding: 8px !important;
  font-family: "Noto Sans", sans-serif !important;
  border-radius: 8px !important;
  background: $white;
  box-shadow: 0px -1px 48px -10px rgba(37, 50, 70, 0.34);
  z-index: 1100;
}

html div#iubenda-cs-banner.iubenda-cs-default-floating div.iubenda-cs-container #iubenda-cs-title {
  font-family: "Mulish", sans-serif !important;
  color: $primary-blue !important;
  margin-block: 0 10px !important;
  font-weight: 600 !important;
  font-size: 40px !important;
  line-height: 1.2 !important;
}

html div#iubenda-cs-banner.iubenda-cs-default-floating div.iubenda-cs-container div#iubenda-cs-paragraph,
html div#iubenda-cs-banner.iubenda-cs-default-floating [class^="iub"] {
  font-family: "Noto Sans", sans-serif !important;
  font-weight: 400 !important;
  color: $dark-blue;
}

html div#iubenda-cs-banner.iubenda-cs-default-floating div.iubenda-cs-container div#iubenda-cs-paragraph p {
  margin-bottom: 4px !important;

  &:last-child {
    margin-bottom: 0 !important;
  }
}

html div#iubenda-cs-banner.iubenda-cs-default-floating div.iubenda-cs-container .iubenda-cs-content .iubenda-granular-controls-container {
  padding-top: 0 !important;
  border: none !important;
}

html div#iubenda-cs-banner.iubenda-cs-default-floating div.iubenda-cs-container .iubenda-cs-content .iubenda-granular-controls-container .granular-control-checkbox {
  background: transparent !important;
  border: none !important;
}

html div#iubenda-cs-banner.iubenda-cs-default-floating .iub-toggle-checkbox.granular-control-checkbox label {
  margin: 0 !important;
}

html div#iubenda-cs-banner.iubenda-cs-default-floating .iub-toggle-checkbox.granular-control-checkbox span {
  font-family: "Noto Sans", sans-serif !important;
  font-weight: 400 !important;
  background: transparent !important;
}

html div#iubenda-cs-banner.iubenda-cs-default-floating .iubenda-cs-opt-group {
  display: flex;
  flex-wrap: wrap !important;
  gap: 12px !important;
  flex-direction: row !important;
  justify-content: space-between !important;

  @media (max-width: $mob) {
    justify-content: center !important;
  }
}

html div#iubenda-cs-banner.iubenda-cs-default-floating .iubenda-cs-opt-group div {
  button {
    width: auto !important;
    margin: 0 !important;
    min-width: fit-content !important;
    border: 1px solid #d8e6ff !important;
    font-family: "Mulish", sans-serif !important;
    font-size: 16px !important;
    font-weight: 700 !important;

    &:hover {
      background: darken($second-brand-color, 2%) !important;
      box-shadow: none !important;
    }
  }
}

html div#iubenda-cs-banner.iubenda-cs-default-floating .iubenda-cs-opt-group > div:last-child {
  width: auto !important;
  margin: 0 !important;
  display: flex;
  flex-wrap: wrap !important;
  gap: 12px !important;
  flex-direction: row !important;
  justify-content: center !important;

  button {
    color: $white !important;
    border: none !important;
    background: $primary-blue !important;

    &:hover {
      background: darken($primary-blue, 10%) !important;
    }
  }
}

html div#iubenda-iframe.iubenda-cs-default-floating .iub-toggle-checkbox input[type="checkbox"] {
  display: block !important;
}

// Purposes container
html #purposes-content-container .purposes-content *:not(h1, h2, h3, h4, h5, h6, button),
#purposes-content-container .purposes-item .purposes-item-body {
  font-family: "Noto Sans", sans-serif !important;
  color: $dark-blue !important;
  font-size: 16px !important;
}

html #purposes-content-container .purposes-content h3,
html #purposes-content-container .purposes-content h4,
html #purposes-content-container .purposes-header .purposes-header-title,
html #purposes-content-container .purposes-item .purposes-item-title label {
  font-family: "Mulish", sans-serif !important;
  font-weight: 700 !important;
  line-height: 1.2 !important;
}

html #purposes-content-container .purposes-content h3 {
  font-size: 20px !important;
}

html #purposes-content-container .purposes-content h4 {
  font-size: 18px !important;
}

html #purposes-content-container .purposes-header .purposes-header-title {
  color: $primary-blue !important;
  margin-block: 0 10px !important;
  font-size: 40px !important;
}

html #purposes-content-container .purposes-item .purposes-item-title label {
  font-size: 18px !important;
}

html #iubenda-iframe #iubFooterBtnContainer button,
html #iubenda-iframe .iub-btn-consent {
  font-family: "Mulish", sans-serif !important;
  font-size: 16px !important;
  font-weight: 700 !important;
  border: 1px solid #d8e6ff !important;
  color: $dark-blue !important;
  background-color: #f1f6ff !important;
  box-shadow: none !important;

  &:hover {
    background-color: #e7efff !important;
    border: 1px solid #d8e6ff !important;
    box-shadow: none !important;
  }
}

html #iubenda-iframe {
  background: rgba(37, 50, 70, 0.5) !important;
}
